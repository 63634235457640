import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 90px 0",
	"quarkly-title": "Headline-4"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"width": "50%",
			"font": "normal 500 52px/1.2 --fontFamily-serifTimes",
			"padding": "0px 50px 0px 0px",
			"lg-width": "100%",
			"lg-margin": "0px 0px 50px 0px",
			"md-padding": "0px 0 0px 0px",
			"md-font": "normal 500 42px/1.2 --fontFamily-serifTimes",
			"sm-margin": "0px 0px 35px 0px",
			"children": "Телефонуйте нам!"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"lg-width": "100%"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "--lead",
			"color": "#727a82",
			"sm-margin": "0px 0px 25px 0px",
			"children": "Приєднуйтесь до Клубу легенд автобану та станьте частиною спадщини, яка вшановує не просто автомобілі, це свято стилю життя. Спілкуйтеся з найкращими, керуйте найкращими, будьте найкращими."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"sm-flex-direction": "column"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"margin": "0px 25px 0px 0px",
			"sm-margin": "0px 0 10px 0px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 15px 0px 0px",
			"font": "--lead",
			"color": "#727a82",
			"white-space": "nowrap",
			"children": "Телефон"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "#000000",
			"text-decoration-line": "initial",
			"font": "--lead",
			"href": "tel:0676391590",
			"children": "0676391590"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"margin": "0px 0 0px 0px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 15px 0px 0px",
			"font": "--lead",
			"color": "#727a82",
			"white-space": "nowrap",
			"children": "Пошта"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"color": "#000000",
			"text-decoration-line": "initial",
			"font": "--lead",
			"href": "mailto:serenalogood.com",
			"children": "helllo@serenalogood.com"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" sm-min-width="280px" />
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Box {...override("box1")}>
				<Text {...override("text1")} />
				<Box {...override("box2")}>
					<Box {...override("box3")}>
						<Text {...override("text2")} />
						<Link {...override("link")} />
					</Box>
					<Box {...override("box4")}>
						<Text {...override("text3")} />
						<Link {...override("link1")} />
					</Box>
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;